import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../Header'

const FactoryFixHeader = ({ featured }: { featured?: boolean }) => {
  const data = useStaticQuery(query)
  const photo = data.hero.childImageSharp.gatsbyImageData
  return (
    <Header
      photo={photo}
      company="FactoryFix"
      heading="How FactoryFix drives alignment and engineering productivity improvements with Swarmia"
      subheading="The engineering organization at FactoryFix uses a combination of Swarmia, Linear, and the Shape Up methodology to align engineering with the business, drive continuous improvement in teams, and deliver customer value faster."
      featured={featured}
    />
  )
}

export default FactoryFixHeader

const query = graphql`
  {
    hero: file(
      relativePath: { eq: "images/story/factoryfix/factoryfix-hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 540, layout: FIXED)
      }
    }
  }
`
