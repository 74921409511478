import React from 'react'
import CTASection from '../../components/CTASection'
import Layout from '../../components/Layout'
import CaseStudyContainer from '../../components/story/CaseStudyContainer'
import ChocoBody from '../../components/story/factoryfix/Content'
import ChocoHeader from '../../components/story/factoryfix/Header'
import MetaImage from '../../assets/images/story/factoryfix/factoryfix-cover.png'
import QuoteBlock from '../../components/blocks/QuoteBlock'

const ChocoPage = () => {
  return (
    <Layout
      title="FactoryFix case study"
      description="The engineering organization at FactoryFix uses a combination of Swarmia, Linear, and the Shape Up methodology to align engineering with the business, drive continuous improvement in teams, and deliver customer value faster."
      metaImage={MetaImage}
    >
      <CaseStudyContainer>
        <ChocoHeader />
        <QuoteBlock person="tim" />
        <ChocoBody />
      </CaseStudyContainer>
      <CTASection>
        Swarmia is how the best teams get better. Get started today.
      </CTASection>
    </Layout>
  )
}
export default ChocoPage
